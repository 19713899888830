<template>
  <main id="content" role="main">
    <section class="visual_intro">
      <!-- 다른곳과 동일하게. 넣어야 할 이미지는 letter/example.png 로 넣어 두었습니다.-->
      <div class="visual_wall">
      </div>
      <div class="intro_title">
        <strong class="title">DREAMPLUS</strong>
        <strong class="title">NEWSLETTER</strong>
        <router-link :to="{name:'LetterRead', params:{}}"  class="link-letter-read">구독하기</router-link>
      </div>
    </section>
    <section class="report_box">
      <div class="news-tab-menu">
        <ul>
          <li><router-link to="/contents/list">NEWS</router-link></li>
          <li class="on"><router-link to="/letter/list">NEWSLETTER</router-link></li>
        </ul>
      </div>
    </section>
    <section class="report_group">
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">NEWSLETTER</h2>
          <p class="description">DREAMPLUS NEWSLETTER를 통해 드림플러스의 다양한 활동 및 생태계 소식을 전해 드립니다.</p>
        </div>
        <div class="letter-list">
          <ul>
            <li v-for="item in dataList">
              <div class="thumb">
                <span :style="`background-image: url(${item.thumbnailUrl});`" @click.prevent="showDetail(item.id)"></span>
              </div>
              <div class="info">
                <span class="title" @click.prevent="showDetail(item.id)">{{ item.title }}</span>
                <span class="content" @click.prevent="showDetail(item.id)">{{ item.description }}</span>
                <a class="link" @click.prevent="showDetail(item.id)">상세내용 보러가기</a>
              </div>
            </li>
          </ul>
        </div>
        <Pagination ref="Pagination" />
      </section>
    </section>
  </main>
</template>

<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { APIs } from '@/common/portalApi';
import Pagination from '@/components/base/Pagination.vue';

export default {
  name: 'LetterList',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    Pagination,
  },
  data() {
    return {
      search: {
        page: 1,
        size: 12,
        order: "letter.id desc",
        data: {
          isShow: true
        }
      },
      totalCount: 0,
      count: 0,
      dataList: [],
      moreVisible: true,
      isMobile: false
    }
  },
  mounted () {
    if (this.isMobile) {
      this.search.size = 9
      this.retrieveNewsList();
    } else {
      this.getList(1);
    }
    const elVisual = document.getElementsByClassName('visual_intro');
    setTimeout(() => {
      elVisual[0].className += ' ani_intro';
    }, 100);
  },
  methods: {
    getList(page) {
      this.search.page = page;
      this.portalApiClient({
        url: APIs.NEWS_LETTER_LIST,
        data: this.$data.search,
      
      }).then(({ data }) => {
        if (data.result) {
          this.dataList = data.list;
          this.totalCount = data.totalCount;
          this.count = data.count;
          this.$refs.Pagination.loadPage(this.count, this.search.size, this.search.page);
        } else {
          this.showAlert(data.message);
        }
      });
    },
    retrieveNewsList() {
      this.portalApiClient({
        url: APIs.NEWS_LETTER_LIST,
        data: this.$data.search,
      }).then(({ data }) => {
        if (data.result) {
          this.dataList = this.dataList.concat(data.list);
          this.totalcount = data.totalCount;
          this.count = data.count;
          this.search.page += 1;
          
          // 더보기 버튼 활성화
          if (this.dataList.length >= this.count) {
            this.moreVisible = false;
          } else {
            this.moreVisible = true;
          }
          if (this.dataList.length > 0) {
            this.dataVisible = false;
          } else {
            this.dataVisible = true;
          }
        }
      });
    },
    retrieveNewsListMore() {
      this.retrieveNewsList();
    },
    showDetail(newsLetterId) {
      this.$router.push({path:`/letter/detail/${newsLetterId}`})
    }
  }
};
</script>
<style scoped>
.letter-list .thumb span, .info .title, .info .content {
  cursor: pointer;
}
</style>
